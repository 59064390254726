<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <span
          v-on="on"
          class="moduleLink"
          data-test="activator"
          :data-cy="`quotes-list-company-name-${tabIndex}-${quoteId}`"
          @click="loadAddressData"
        >
          {{ displayString }}
        </span>
      </template>

      <v-card>
        <v-card-title>
          <span class="primary--text" data-test="title">{{
            displayString
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <v-list-item class="ma-0 pa-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 warning--text"
                    >Company Billing</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="9">
              <v-list-item class="ma-0 pa-0 subtitle-1" v-if="billing_address">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="billing_name"
                    >{{ billing_address.data.company_name }} {{ billing_address.data.name }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-if="billing_address.data.street1"
                    class="primary--text subtitle-2"
                    data-test="billing_street1"
                  >
                    {{ billing_address.data.street1 }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="billing_address.data.street2"
                    class="primary--text subtitle-2"
                    data-test="billing_street2"
                  >
                    {{ billing_address.data.street2 }}
                  </v-list-item-title>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="billing_address2"
                  >
                    {{ billing_address.data.city }}, {{ billing_address.data.state }}, {{ billing_address.data.zip }}
                  </v-list-item-title>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="billing_email_phone"
                    >{{ billing_address.data.email }}, {{ billing_address.data.phone }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <v-list-item class="ma-0 pa-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 warning--text"
                    >Shipping Address</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="9">
              <v-list-item v-if="shippingSameAsBilling" class="ma-0 pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="subtitle-2 primary--text"
                    data-test="shipping_same_as_billing"
                    >Shipping Same As Billing</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!shippingSameAsBilling && shipping_address" class="ma-0 pa-0 subtitle-1">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="shipping_name"
                    >{{ shipping_address.data.company_name }} {{ shipping_address.data.name }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-if="shipping_address.data.street1"
                    class="primary--text subtitle-2"
                    data-test="shipping_street1"
                  >
                    {{ shipping_address.data.street1 }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="shipping_address.data.street2"
                    class="primary--text subtitle-2"
                    data-test="shipping_street2"
                  >
                    {{ shipping_address.data.street2 }}
                  </v-list-item-title>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="shipping_address2"
                  >
                    {{ shipping_address.data.city }}, {{ shipping_address.data.state }}, {{ shipping_address.data.zip }}
                  </v-list-item-title>
                  <v-list-item-title
                    class="primary--text subtitle-2"
                    data-test="shipping_email_phone"
                    >{{ shipping_address.data.email }}, {{ shipping_address.data.phone }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            data-test="close_button"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "CompanyDetailsView",
  props: {
    displayString: {
      type: String,
      default: null,
    },
    billingName: {
      type: String,
      default: null,
    },
    billingCompany: {
      type: String,
      default: null,
    },
    billingStreet1: {
      type: String,
      default: null,
    },
    billingStreet2: {
      type: String,
      default: null,
    },
    billingAddress2: {
      type: String,
      default: null,
    },
    billingEmail: {
      type: String,
      default: null,
    },
    billingPhone: {
      type: String,
      default: null,
    },
    shippingName: {
      type: String,
      default: null,
    },
    shippingCompany: {
      type: String,
      default: null,
    },
    shippingStreet1: {
      type: String,
      default: null,
    },
    shippingStreet2: {
      type: String,
      default: null,
    },
    shippingAddress2: {
      type: String,
      default: null,
    },
    shippingEmail: {
      type: String,
      default: null,
    },
    shippingPhone: {
      type: String,
      default: null,
    },
    shippingSameAsBilling: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: null,
    },
    quoteId: {
      type: Number,
      default: null,
    },
    billingAddressId: {
      type: Number,
      default: null,
    },
    shippingAddressId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      billing_address: null,
      shipping_address: null,
    };
  },
  methods: {
    async loadAddressData() {
      console.log(this.billingAddressId);
      this.billing_address = await this.$store.dispatch(
        "quotes/getQuoteAddress",
        {
          id: this.billingAddressId,
          isBilling: true,
        }
      );
      if (!this.shippingSameAsBilling) {
        this.shipping_address = await this.$store.dispatch(
          "quotes/getQuoteAddress",
          {
            id: this.shippingAddressId,
            isBilling: true,
          }
        );
      }
      console.log(this.billingAddressId, this.billing_address, this.shipping_address);
    },
  },
};
</script>
